export enum Paths {
  login = '/auth/signin',
  recoverPassword = '/auth/password/recover',
  resetPassword = '/auth/password/reset/',
  resetRegularPassword = '/auth/collaborator/password/reset/',
  whoami = '/auth/whoami',
  businessUnit = '/business_unit/',
  surveyList = '/survey/list',
  createEditSurvey = '/survey/',
  cloneSurvey = '/survey/clone/',
  downloadSurvey = '/survey/report/',
  sendSurvey = '/survey/send/',
  activeSurvey = '/survey/active/',
  collaborators = '/collaborator/',
  users = '/user/',
  userRoles = '/role/',
  createUser = '/user/',
  changePassword = '/auth/signup/',
  changeCollaboratorPassword = '/auth/collaborator/signup/',
  sendSMS = '/auth/sms/',
  payrollComplaintsXlsx = '/collaborator/payroll_complaint',
  anonymousComplaintsXlsx = '/complaint/download',
  exitReportXlsx = '/survey/exit/report',
  recruitXlsx = '/recruit/report',
  coppelMovementsXlsx = '/journey_pay/coppel/transactions',
  coppelBalancesXlsx = '/journey_pay/coppel/balances',
  colaboratorsBalanceXlsx = '/journey_pay/report/collaborator_balance',
  activesJourneyXlsx = '/indicator/xlsx/active_collaborators',
  vacantXlsx = '/vacant/postulant/report',
  smsReports = '/sms/reports/',
  campaignXlsx = '/campaign/download/',
  savingsApplicationsXlsx = '/saving/request_report',
  withdrawalsSavingsXlsx = '/saving/report',
  movementsSavingsXlsx = '/saving/single_transaction_report/',
  sendCampaign = '/campaign/',
  getCampaign = '/campaign/',
  campaignTemplate = '/campaign/message_template',
  deleteTemplate = '/campaign/message_template/',
  uploadImage = '/upload/',
  leavings = '/leaving/notification',
  awards = '/prize/',
  prizeExchange = '/prize/applicant_collaborators',
  exchangePrize = '/prize/exchange_code',
  cancelPrize = '/prize/exchange_code/cancel/',
  exchangeCode = '/prize/exchange_code/redeem/',
  sendPrizeMessage = '/prize/notify_tracing',
  collabDetailXlsx = '/collaborator/download_report',
  leavingsReport = '/leaving/report',
  surveysReport = '/survey/report/answered/',
  leavingDischarge = '/leaving/notification/discharge/',
  getTop = '/collaborator/ranking/top',
  levelsUp = '/collaborator/ranking/week_upgrades',
  vacant = '/vacant/',
  guideline = '/guideline/',
  complaintTracking = '/complaint/payroll',
  causesReason = '/complaint/payroll/complaint_cause',
  statusReason = '/complaint/payroll/complaint/viability_status',
  sendNotification = '/collaborator/schedule_message',
  challengesReport = '/challenge/challenge_completed/report',
  challenge = '/challenge/',
  review = '/review/',
  productionRanking = '/collaborator/ranking/production',
  productionRankingReport = '/collaborator/ranking/production/report',
  reviewGeneralReport = '/review/general_report/',
  downloadReview = '/review/response_report/',
  appointmentReport = '/appointment/report',
  appointmentIventoryReport = '/appointment/inventory',
  downloadNotification = '/collaborator/schedule_message/confirm_report/',
  modulesApp = '/collaborator/mobile_module',
  crops = '/collaborator/ranking/production/crop',
  activities = '/collaborator/ranking/production/activity',
  weekNo = '/collaborator/ranking/production/week_no',
  payrollWeeks = '/business_unit/nominal_weeks',
  menu = '/menu/',
  syncAgro = '/collaborator/sync_agrosmart',
  downloadMenu = '/menu/download',
  appointment = '/appointment/',
  availableAppointments = '/appointment/available_time/',
  appointmentCalendar = '/appointment/calendar/',
  appointmentDetail = '/appointment/attend/',
  appointmentCancel = '/appointment/cancel/',
  workingPopulation = '/indicator/working_population',
  usersDaily = '/indicator/daily_users',
  usersPrize = '/indicator/prize',
  weeklyUsers = '/indicator/weekly_users',
  specialAttend = '/appointment/special_attend/',
  generalReview = '/indicator/review/general',
  supervisorReview = '/indicator/review/supervisor',
  reviewComments = '/indicator/review/comments',
  payrollComplaint = '/indicator/payroll_complaint',
  payrollComplaintDetail = '/indicator/payroll_complaint/detail',
  anonymousComplaint = '/indicator/complaint',
  anonymousComplaintSeries = '/indicator/complaint/series',
  anonymousComplaintDetail = '/indicator/complaint/detail',
  medicalAppointment = '/indicator/medical_appointment',
  challengesCompletion = '/indicator/challenge',
  challengesDetail = '/indicator/challenge/detail',
  surveyIndicators = '/indicator/survey',
  surveyIndicatorsDetails = '/indicator/survey/detail',
  recordList = '/appointment/record_list',
  diagnostics = '/appointment/diagnosis',
  diagnosticClass = '/appointment/diagnostic_class',
  medicalClass = '/appointment/medical_supply',
  incapacityDays = '/appointment/incapacity_days/',
  payrollWeeksCatalog = '/collaborator/paysheet_week_catalog',
  examination = '/appointment/examination_attend/',
  relatives = '/appointment/relatives',
  hereditary_diseases = '/appointment/hereditary_diseases',
  state = '/appointment/state',
  religion = '/appointment/religion',
  activities_carried_out = '/appointment/performed_activity',
  work_shifts = '/appointment/work_shifts',
  way_do_activity = '/appointment/way_do_activity',
  working_action = '/appointment/working_action',
  personal_protection = '/appointment/personal_protection',
  accident_cause = '/appointment/accident_cause',
  injury_type = '/appointment/injury_type',
  injured_body_part = '/appointment/injured_body_part',
  sequel = '/appointment/sequel',
  childhood_disease = '/appointment/childhood_disease',
  pathology = '/appointment/pathology',
  accidents = '/appointment/personal_accident',
  fractures = '/appointment/fracture',
  save_examination_test = '/appointment/entrance_examination',
  awards_update_status = '/prize/status/',
  complaints = '/complaint/',
  workingPopulationReport = '/indicator/working_population_report',
  ops_supervisor = '/ops_supervisor/',
  lots_ops = '/ops_supervisor/ops_lots',
  ops_indicator = '/ops_supervisor/',
  downloadEI = '/appointment/entrance_examination/xlsx',
  download_recipe = '/appointment/recipe/',
  collaborator_recovered = '/collaborator/recovered',
  journeyPointXlsx = '/journey_pay/report',
  journeyPay = '/journey_pay/balances',
  saving = '/saving',
  bannerList = '/banner/list',
  banner = '/banner/',
  createBanner = '/banner',
  createContact = '/telephone_directory',
  directory = '/telephone_directory/list',
  planning = '/ops/manpower/staff',
  planningReport = '/ops/manpower/staff/xlsx?',
  positionsXlsx = '/regular_collaborator/position_slot/xlsx',
  jobs = '/regular_collaborator/position',
  jobsXlsx = '/regular_collaborator/position/xlsx',
  createJob = '/regular_collaborator/position',
  jobsLevels = '/regular_collaborator/position_level',
  jobsDirections = '/regular_collaborator/direction',
  jobsAreas = '/regular_collaborator/area',
  jobsDepartments = '/regular_collaborator/department',
  states = '/appointment/state',
  municipalities = '/appointment/municipality',
  companies = '/business_unit/company',
  position = '/regular_collaborator/position_slot', 
  employees = '/regular_collaborator',
  employeesXlsx = '/regular_collaborator/xlsx',
  createEmployee = '/regular_collaborator',
  vacations = '/vacation/collaborator/list',
  vacationSummary = '/vacation/summary',
  vacationDayEdit = '/vacation/days/collaborator',
  ticket = '/ticket',
  tickets = '/ticket/my_requests',
  ticketsCategories = '/ticket/request_type',
  myTickets = '/ticket/mine',
  vacationsXlsx = '/vacation/xlsx',
}
